/* eslint-disable no-undef */
import 'amazon-connect-streams';

import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { CommonIcons } from 'common-src/assets/Icons';
import Config from 'common-src/config';
import { getCurrentUserId, getRoleConfig } from 'common-src/features/auth';
import { apiRequest, HttpMethods } from 'common-src/features/rest';

import { ModalType } from 'src/components/base/ModalGroup';
import {
  getContactToDial,
  isAwsDialerVisible,
  updateAgentState,
  updateAwsDialerVisibility,
  updateContactToDial,
} from 'src/features/awsConnect';
import { openModal } from 'src/features/modals';
import { getPatientsPaths } from 'src/features/tabsState';
import useCustomSelector from 'src/hooks/useCustomSelector';
import useTabs from 'src/hooks/useTabs';

import styles from './AwsConnectDialer.module.scss';
import { ContactType } from './constants';

const AwsConnectDialer = () => {
  const dispatch = useDispatch();
  const isVisible = useCustomSelector((state) => isAwsDialerVisible(state));
  const contactToDial = useCustomSelector((state) => getContactToDial(state));
  const clientId = useCustomSelector((state) => getCurrentUserId(state));
  const patientsPaths = useCustomSelector((state) => getPatientsPaths(state));
  const shouldShowDispositionModal =
    useCustomSelector((state) => getRoleConfig(state)?.canSetCallDisposition) || false;

  const containerDivRef = useRef(null);
  const patientIdRef = useRef(null);
  const phoneToDialRef = useRef(null);

  const [isCoreInitialized, setIsCoreInitialized] = useState(false);
  const [agent, setAgent] = useState(null);

  const { onSelectTab } = useTabs();

  // dial phone number side effect
  useEffect(() => {
    if (!contactToDial || !isCoreInitialized || !agent) return;

    const { phoneToDial, patientId } = contactToDial;

    const phone = phoneToDial.startsWith('+1') ? phoneToDial : `+1${phoneToDial}`;
    const endpoint = connect.Endpoint.byPhoneNumber(phone);

    patientIdRef.current = patientId;
    phoneToDialRef.current = phone;

    agent.connect(endpoint, {
      success: () => {
        console.log('[CCP]] outbound call connected');
      },
      failure: (err) => {
        console.log('[CCP]] outbound call connection failed', err);
        patientIdRef.current = null;
        phoneToDialRef.current = null;
      },
    });

    dispatch(updateContactToDial(null));
  }, [contactToDial, isCoreInitialized, agent]);

  const handleContactEvent = (attributes, snapshot) => {
    const contactType = snapshot?.contactData?.type;
    if (![ContactType.Voice, ContactType.Task].includes(contactType)) return;

    const patientId = attributes?.MRN?.value || snapshot?.contactData?.references?.Mrn?.value;
    if (!patientId) return;

    const path = patientsPaths[patientId]?.path;
    onSelectTab({
      path: path || `/dashboard-client/patients/details/${patientId}/info`,
    });
  };

  const initAwsConnect = () => {
    // Initialize
    connect.core.initCCP(containerDivRef.current, {
      ccpUrl: `${Config.AWS_CONNECT_URL}/ccp-v2`,
      loginUrl: `${Config.AWS_CONNECT_URL}/ccp-v2`,
      loginPopup: true,
      loginPopupAutoClose: true,
      region: 'us-east-1',
      softphone: {
        allowFramedSoftphone: true,
      },
      ccpAckTimeout: 6000, // optional, defaults to 3000 (ms)
      ccpSynTimeout: 4000, // optional, defaults to 1000 (ms)
      ccpLoadTimeout: 8000, // optional, defaults to 5000 (ms)
    });

    // Initialize callbacks
    connect.core.onInitialized(() => setIsCoreInitialized(true));

    connect.agent((agent) => {
      agent.onWebSocketConnectionGained(() => {
        console.log('[CCP]] - Logged in');
      });

      agent.onStateChange((state) => {
        console.log('[CCP]] State change', state);
        dispatch(updateAgentState(state.newState));
      });

      setAgent(agent);
    });

    // could add onConnected callback for viewing contact event
    connect.contact((contact) => {
      contact.onAccepted((contact) => {
        handleContactEvent(contact.getAttributes(), contact.toSnapshot());
      });

      contact.onConnecting((contact) => {
        const data = contact.toSnapshot()?.contactData;

        if (data?.initiationMethod !== 'outbound') return;

        const connections = data?.connections || [];
        const phoneNumber = connections.find(
          (connection) =>
            connection?.address?.type !== 'agent' &&
            !!connection?.address?.phoneNumber &&
            connection?.address?.phoneNumber.startsWith('+1'),
        )?.address?.phoneNumber;

        apiRequest({
          endpoint: 'awscCall',
          method: HttpMethods.Post,
          body: {
            id: contact.contactId,
            clientId,
            patientId: patientIdRef.current || undefined,
            customerEndpointAddress: phoneNumber || phoneToDialRef.current || undefined,
          },
        });
      });

      contact.onEnded((contact) => {
        if (!shouldShowDispositionModal) return;

        const { contactData, contactId } = contact.toSnapshot();

        if (![ContactType.Voice, ContactType.QueueCallback].includes(contactData?.type)) return;

        if (contactData?.initiationMethod !== 'outbound') return;

        dispatch(
          openModal(ModalType.AWSC_CALL_DISPOSITION, {
            awscCallId: contactId,
          }),
        );
      });
    });

    // Events listener
    const eventBus = connect.core.getEventBus();
    eventBus.subscribe(connect.EventType.TERMINATED, () => console.log('[CCP]] - Logged out'));
  };

  useEffect(() => {
    let timer;

    // initialize CCP
    if (isVisible && !isCoreInitialized) {
      timer = setTimeout(() => initAwsConnect(), 1000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [isVisible, isCoreInitialized]);

  return (
    <div className={[styles.container, isVisible ? '' : styles.hidden].join(' ')}>
      <img
        className={[styles.closeBtn, isCoreInitialized ? '' : styles.hidden].join(' ')}
        src={CommonIcons.cancelIcon}
        alt="close-aws-dialer"
        onClick={() => dispatch(updateAwsDialerVisibility(false))}
        role="presentation"
      />
      <div id="aws-connect-id" className={styles.softphone} ref={containerDivRef} />
    </div>
  );
};

export default AwsConnectDialer;
