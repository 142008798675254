import { roles } from 'common-src/models/Client/constants';

import {
  defaultPermissions,
  dispositionPermissions,
  rolePermissions as permissionsByRole,
} from './constants';

export const createRoleConfigPayload = (roleId, isSuperAdmin) => {
  const permissions = { ...defaultPermissions };
  const rolePermissions = permissionsByRole[isSuperAdmin ? roles.Admin.id : roleId];
  const canSetCallDisposition = dispositionPermissions[roleId];

  return Object.assign(permissions, {
    ...rolePermissions,
    canSetCallDisposition,
    patientChart: {
      actions: {
        ...permissions.patientChart.actions,
        ...rolePermissions.patientChart.actions,
      },
      sections: {
        ...permissions.patientChart.sections,
        ...rolePermissions.patientChart.sections,
        physicianOrders:
          rolePermissions.patientChart.sections.physicianOrders === 'view-only'
            ? 'view-only'
            : {
                ...rolePermissions.patientChart.sections.physicianOrders,
                assignedAsAC: roleId === roles.AccountabilityCoach.id,
                assignedAsRD: roleId === roles.RD.id,
              },
      },
    },
  });
};

export const isViewOnly = (permission) => permission === 'view-only';
