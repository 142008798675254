import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';

import { Menu } from 'common-src/components/base';
import Patient from 'common-src/models/Patient';
import {
  getScheduleVisitQueryParams,
  ScheduleVisitLinks,
} from 'common-src/models/PatientOncehubBooking';
import { getPrimaryPatientPhoneNumber } from 'common-src/models/PatientPhoneNumber';

import Icons from 'src/assets/Icons';
import { ModalType } from 'src/components/base/ModalGroup';
import { openModal } from 'src/features/modals';
import useCustomSelector from 'src/hooks/useCustomSelector';

import styles from '../commonStyles.module.scss';

const ScheduleVisitMenu = ({ patient, tooltipText }) => {
  const dispatch = useDispatch();

  const primaryPatientPhoneNumber = useCustomSelector((state) =>
    getPrimaryPatientPhoneNumber(state, patient?.id),
  );

  const options = Object.values(ScheduleVisitLinks).map((op) => ({
    text: op.label,
    id: op.value,
    onClick: () => {
      if (op.label === ScheduleVisitLinks.DeviceTraining.label) {
        dispatch(openModal(ModalType.ADD_TASK, { patientId: patient.id }));
        return;
      }

      window.open(
        `${op.value}${getScheduleVisitQueryParams(
          patient,
          primaryPatientPhoneNumber,
          op.linkType,
        )}`,
        '_blank',
      );
    },
  }));

  return (
    <Menu
      button={
        <img
          id="schedule-visit-button"
          className={styles.actionButton}
          src={Icons.calendarPlusIcon}
          alt="schedule-visit-icon"
        />
      }
      options={options}
      tooltipText={tooltipText}
    />
  );
};

ScheduleVisitMenu.propTypes = {
  patient: PropTypes.exact(Patient.schema),
  tooltipText: PropTypes.string,
};

export default ScheduleVisitMenu;
