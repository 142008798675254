export const PatientTaskStatus = Object.freeze({
  Scheduled: { value: 0, label: 'Scheduled', textColor: '#FFFFFF', backgroundColor: '#9D97C7' },
  New: { value: 1, label: 'New', textColor: '#FFFFFF', backgroundColor: '#9D97C7' },
  InProgress: { value: 2, label: 'In Progress', textColor: '#FFFFFF', backgroundColor: '#F1BF72' },
  Completed: { value: 3, label: 'Completed', textColor: '#FFFFFF', backgroundColor: '#637768' },
  Canceled: { value: 4, label: 'Canceled', textColor: '#FFFFFF', backgroundColor: '#F01F51B2' },
  UnableToComplete: {
    value: 5,
    label: 'Unable to Complete',
    textColor: '#FFFFFF',
    backgroundColor: '#F01F51B2',
  },
});

export const ActivePatientTaskStatuses = [
  PatientTaskStatus.Scheduled.value,
  PatientTaskStatus.New.value,
  PatientTaskStatus.InProgress.value,
];

// Types
export const ALL_TASK_TYPES = Object.freeze({
  value: '',
  label: 'All Types',
});

export const TaskType = Object.freeze({
  SocialWorkReferral: { value: 1, label: 'Social Work Referral' },
  Administrative: { value: 2, label: 'Administrative' },
  Diabetes: { value: 3, label: 'Diabetes' },
  Compliance: { value: 4, label: 'Compliance' },
  ScheduleVisit: { value: 5, label: 'Schedule Visit' },
  RescheduleVisit: { value: 6, label: 'Reschedule Visit' },
  BGM: { value: 7, label: 'BGM' },
  CGM: { value: 8, label: 'CGM' },
  Engagement: { value: 9, label: 'Engagement' },
  BillingAndInsurance: { value: 10, label: 'Billing And Insurance' },
  CareCoordination: { value: 11, label: 'Care Coordination' },
});

// Reasons
export const ALL_TASK_TYPE_REASONS = Object.freeze({
  value: '',
  label: 'All Reasons',
});

export const TaskReason = Object.freeze({
  NewReferral: { value: 1, label: 'New Referral' },
  NewIssue: { value: 2, label: 'New Issue' },
  NeedsRpmApproval: { value: 3, label: 'Needs Rpm Approval' },
  AccountClosureNeeded: { value: 4, label: 'Account Closure Needed' },
  MFARequest: { value: 5, label: 'MFA Request' },
  CommercialPlanReview: { value: 6, label: 'Commercial Plan Review' },
  HypoglycemicRisk: {
    value: 7,
    label: 'Chart indicates increased risk of hypoglycemic events over the last 30 days',
  },
  BiannualVisit: { value: 8, label: 'Chart indicates that member is due for a bi-annual visit' },
  MFARenewal: { value: 9, label: 'Member’s MFA is about to expire' },
  CGMAssessment: { value: 10, label: 'CGM Assessment needed' },
  InteractionAdjustment: { value: 11, label: 'Interaction Adjustments' },
  ClinicalFirstVisit: { value: 12, label: 'Clinical First Visit' },
  InitialCoachingCall: { value: 13, label: 'Initial Coaching Call' },
  MonthlyWellnessVisit: { value: 14, label: 'Monthly Wellness Visit' },
  NutritionMedicationAssessment: { value: 15, label: 'Nutrition Medication Assessment' },
  MedicationReconciliation: { value: 16, label: 'Medication Reconciliation' },
  QuarterlyCheckIn: { value: 17, label: 'Quarterly Check-in' },
  ClinicalMeeting: { value: 18, label: 'Clinical Meeting' },
  InsulinPumpTrainingVisit: { value: 19, label: 'Insulin Pump Training Visit' },
  MFADecision: { value: 20, label: 'MFA Decision' },
  BGMDeviceTrainingVisit: { value: 21, label: 'BGM Device Training Visit' },
  CGMDeviceTrainingVisit: { value: 22, label: 'CGM Device Training Visit' },
  FirstReadingOutstanding: { value: 23, label: 'First Reading Outstanding' },
  MemberNeedsSupplies: { value: 24, label: 'Member Needs Supplies' },
  DeliveredToWrongAddress: { value: 25, label: 'Delivered To Wrong Address' },
  SetupNeeded: { value: 26, label: 'Setup Needed' },
  DeviceTroubleshooting: { value: 27, label: 'Device Troubleshooting' },
  QualificationRequested: { value: 28, label: 'Qualification Requested' },
  QuestionAboutOrder: { value: 29, label: 'Question About Order' },
  NoRecentBGMReadings: { value: 30, label: 'No Recent BGM Readings' },
  NoRecentCGMReadings: { value: 31, label: 'No Recent CGM Readings' },
  PhoneNumberNotInService: { value: 32, label: 'Phone Number Not In Service' },
  NoUpcomingScheduledVisits: { value: 33, label: 'No Upcoming Scheduled Visits' },
  FollowUpOnInvoice: { value: 34, label: 'Follow Up On Invoice' },
  BillingQuestion: { value: 35, label: 'Billing Question' },
  SecondaryInsuranceInformationNeeded: {
    value: 36,
    label: 'Secondary Insurance Information Needed',
  },
  InsurancePlanChanged: { value: 37, label: 'Insurance Plan Changed' },
  IneligiblePlan: { value: 38, label: 'Ineligible Plan' },
  PreClosureReachOut: { value: 39, label: 'Pre Closure Reach Out' },
  FaxRecords: { value: 40, label: 'Fax Records' },
  CollectVitals: { value: 41, label: 'Collect Vitals' },
  A1cIncrease: { value: 42, label: 'A1C Increase' },
  CollectA1c: { value: 43, label: 'Collect A1C' },
  CGMReviewNeeded: { value: 44, label: 'CGM Review Needed' },
});

export const CREATABLE_TASK_TYPES = [
  TaskType.SocialWorkReferral,
  TaskType.Administrative,
  TaskType.Diabetes,
  TaskType.Compliance,
  TaskType.ScheduleVisit,
  TaskType.RescheduleVisit,
  TaskType.BGM,
  TaskType.CGM,
  TaskType.Engagement,
  TaskType.BillingAndInsurance,
  TaskType.CareCoordination,
];

export const CREATABLE_TASK_REASONS = [
  TaskReason.NewReferral,
  TaskReason.NewIssue,
  TaskReason.NeedsRpmApproval,
  TaskReason.AccountClosureNeeded,
  TaskReason.MFARequest,
  TaskReason.CommercialPlanReview,
  TaskReason.HypoglycemicRisk,
  TaskReason.BiannualVisit,
  TaskReason.MFARenewal,
  TaskReason.CGMAssessment,
  TaskReason.InteractionAdjustment,
  TaskReason.ClinicalFirstVisit,
  TaskReason.InitialCoachingCall,
  TaskReason.MonthlyWellnessVisit,
  TaskReason.NutritionMedicationAssessment,
  TaskReason.MedicationReconciliation,
  TaskReason.QuarterlyCheckIn,
  TaskReason.ClinicalMeeting,
  TaskReason.InsulinPumpTrainingVisit,
  TaskReason.MFADecision,
  TaskReason.BGMDeviceTrainingVisit,
  TaskReason.CGMDeviceTrainingVisit,
  TaskReason.FirstReadingOutstanding,
  TaskReason.MemberNeedsSupplies,
  TaskReason.DeliveredToWrongAddress,
  TaskReason.SetupNeeded,
  TaskReason.DeviceTroubleshooting,
  TaskReason.QualificationRequested,
  TaskReason.QuestionAboutOrder,
  TaskReason.NoRecentBGMReadings,
  TaskReason.NoRecentCGMReadings,
  TaskReason.PhoneNumberNotInService,
  TaskReason.NoUpcomingScheduledVisits,
  TaskReason.FollowUpOnInvoice,
  TaskReason.BillingQuestion,
  TaskReason.SecondaryInsuranceInformationNeeded,
  TaskReason.InsurancePlanChanged,
  TaskReason.IneligiblePlan,
  TaskReason.PreClosureReachOut,
  TaskReason.FaxRecords,
];

export const TASK_REASON_MAPPING = Object.freeze({
  [TaskType.SocialWorkReferral.value]: [TaskReason.NewReferral.value, TaskReason.NewIssue.value],
  [TaskType.Administrative.value]: [
    TaskReason.NeedsRpmApproval.value,
    TaskReason.AccountClosureNeeded.value,
    TaskReason.MFARequest.value,
    TaskReason.CommercialPlanReview.value,
    TaskReason.MFARenewal.value,
    TaskReason.MFADecision.value,
  ],
  [TaskType.Diabetes.value]: [TaskReason.HypoglycemicRisk.value],
  [TaskType.Compliance.value]: [],
  [TaskType.ScheduleVisit.value]: [
    TaskReason.BiannualVisit.value,
    TaskReason.CGMAssessment.value,
    TaskReason.ClinicalFirstVisit.value,
    TaskReason.InitialCoachingCall.value,
    TaskReason.MonthlyWellnessVisit.value,
    TaskReason.NutritionMedicationAssessment.value,
    TaskReason.MedicationReconciliation.value,
    TaskReason.QuarterlyCheckIn.value,
    TaskReason.ClinicalMeeting.value,
    TaskReason.InsulinPumpTrainingVisit.value,
    TaskReason.BGMDeviceTrainingVisit.value,
    TaskReason.CGMDeviceTrainingVisit.value,
  ],
  [TaskType.RescheduleVisit.value]: [
    TaskReason.BiannualVisit.value,
    TaskReason.CGMAssessment.value,
    TaskReason.ClinicalFirstVisit.value,
    TaskReason.InitialCoachingCall.value,
    TaskReason.MonthlyWellnessVisit.value,
    TaskReason.NutritionMedicationAssessment.value,
    TaskReason.MedicationReconciliation.value,
    TaskReason.QuarterlyCheckIn.value,
    TaskReason.ClinicalMeeting.value,
    TaskReason.InsulinPumpTrainingVisit.value,
    TaskReason.BGMDeviceTrainingVisit.value,
    TaskReason.CGMDeviceTrainingVisit.value,
  ],
  [TaskType.BGM.value]: [
    TaskReason.FirstReadingOutstanding.value,
    TaskReason.DeviceTroubleshooting.value,
    TaskReason.MemberNeedsSupplies.value,
    TaskReason.DeliveredToWrongAddress.value,
  ],
  [TaskType.CGM.value]: [
    TaskReason.FirstReadingOutstanding.value,
    TaskReason.SetupNeeded.value,
    TaskReason.DeviceTroubleshooting.value,
    TaskReason.QualificationRequested.value,
    TaskReason.QuestionAboutOrder.value,
  ],
  [TaskType.Engagement.value]: [
    TaskReason.NoRecentBGMReadings.value,
    TaskReason.NoRecentCGMReadings.value,
    TaskReason.PhoneNumberNotInService.value,
    TaskReason.NoUpcomingScheduledVisits.value,
    TaskReason.PreClosureReachOut.value,
  ],
  [TaskType.BillingAndInsurance.value]: [
    TaskReason.MFADecision.value,
    TaskReason.FollowUpOnInvoice.value,
    TaskReason.BillingQuestion.value,
    TaskReason.SecondaryInsuranceInformationNeeded.value,
    TaskReason.InsurancePlanChanged.value,
    TaskReason.IneligiblePlan.value,
  ],
  [TaskType.CareCoordination.value]: [TaskReason.FaxRecords.value],
});
